import React from "react";
import { Header } from "../header";
import { GlobalStyles } from "../../styles/global";
import { Footer } from "../footer";

export const LayoutDefault = (props: any) => {
  const { children } = props;

  return (
    <>
      <GlobalStyles />
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
};
