import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { ViewportSize } from "../../components/grid/content-blocks/container/types";

const StyledFooter = styled.footer`
  border-top: 1px solid #eee;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem auto 0;
  padding: 1rem;
  max-width: var(--content-width-max);

  @media (min-width: ${ViewportSize.TABLET}px) {
    width: var(--content-width-tablet);
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    padding: 2rem 1rem;
    width: var(--content-width-desktop);
  }
`;

const Copyright = styled.div``;

const FooterLinkWrapper = styled.div`
  margin-left: auto;
`;
const FooterLink = styled(Link)`
  text-decoration: none;
  margin-left: 1rem;
  color: var(--brand);
  font-weight: bold;
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <Copyright>&copy; {new Date().getFullYear()} Tanveer Karim</Copyright>
      <FooterLinkWrapper>
        <FooterLink to="/">Home</FooterLink>
        <FooterLink to="/articles">Articles</FooterLink>
        <FooterLink to="/work">Work</FooterLink>
        <FooterLink to="/contact">Contact</FooterLink>
      </FooterLinkWrapper>
    </StyledFooter>
  );
};
