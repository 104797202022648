import React from "react";
import styled from "styled-components";

export const LogoSvg = styled.svg`
  width: ${(props) => props.theme.width};
  height: ${(props) => props.theme.height};

  path {
    fill: var(--brand);
  }
`;

export const Logo: React.FunctionComponent<any> = (props: any) => {
  return (
    <LogoSvg
      width="186.789px"
      height="186.789px"
      viewBox="0 0 186.789 186.789"
      enable-background="new 0 0 186.789 186.789"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      xmlSpace="preserve"
      role="img"
      aria-labelledby="logotitle logodesc"
    >
      <title id="logotitle">Logo</title>
      <desc id="logodesc">Logo for Tanveer Karim</desc>
      <path
        d="M93.395,186.789C41.896,186.789,0,144.893,0,93.395S41.896,0,93.395,0s93.395,41.896,93.395,93.395
      S144.893,186.789,93.395,186.789z M93.395,5C44.654,5,5,44.653,5,93.395s39.654,88.395,88.395,88.395
      c48.741,0,88.395-39.653,88.395-88.395S142.136,5,93.395,5z"
      />
      <path
        d="M114.213,92.909c-1.162-1.83-2.449-3.292-3.854-4.393c-1.408-1.09-2.96-1.945-4.655-2.572
      c1.162-0.626,2.3-1.409,3.418-2.347c1.114-0.939,2.186-2.126,3.215-3.551l27.804-37.181h-19.701c-2.236,0-4.123,0.311-5.664,0.938
      c-1.542,0.626-2.917,1.81-4.119,3.557L89.819,76.966c-1.476,2.092-3.885,3.146-7.237,3.146h-3.577V54.247l-0.112,0.044L57.46,39.423
      l0.165,40.825l-10.81,2.098v8.542c0,1.419,0.396,2.481,1.194,3.21c0.796,0.734,1.762,1.096,2.896,1.096h6.213l0.342,30.586
      c0,3.247,0.466,6.188,1.41,8.826c0.942,2.634,2.32,4.88,4.127,6.739c1.817,1.861,4.046,3.302,6.713,4.313
      c2.657,1.01,5.729,1.517,9.206,1.517c3.769,0,7.416-0.537,10.946-1.594c3.531-1.058,6.701-2.651,9.53-4.765l-6.634-10.373
      c-0.421-0.658-0.836-1.147-1.233-1.454c-0.398-0.307-0.935-0.462-1.594-0.462c-0.467,0-0.882,0.086-1.232,0.249
      c-0.353,0.168-0.727,0.358-1.126,0.564c-0.406,0.216-0.873,0.397-1.418,0.567c-0.544,0.168-1.209,0.246-2.015,0.246
      c-1.548,0-2.741-0.551-3.561-1.656c-0.821-1.103-1.234-2.513-1.234-4.195l-0.324-27.639h5.438c2.102,0,3.762,0.263,4.989,0.771
      c1.23,0.516,2.249,1.395,3.053,2.647l22.114,43.161c0.444,0.668,0.914,1.256,1.409,1.775c0.489,0.514,1.07,0.944,1.741,1.308
      c0.669,0.354,1.46,0.622,2.377,0.799c0.916,0.182,2.02,0.27,3.319,0.27h20.237L114.213,92.909z"
      />
    </LogoSvg>
  );
};

LogoSvg.defaultProps = {
  theme: {
    width: "100px",
    height: "100px",
  },
};
