/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import {
  SeoInterface,
  MetaTagInterface,
  SiteMetadataInterface,
  SchemaType,
} from "./types";
import organizationSchema from "./schema/organization.json";
import websiteSchema from "./schema/website.json";
import webpageSchema from "./schema/webpage.json";
import authorSchema from "./schema/author.json";
import articleSchema from "./schema/article.json";
import imageSchema from "./schema/image.json";
import { EntryInterface, EntrySectionHandle } from "../../types/entries";

const buildSchemaData = (
  siteQuery: SiteMetadataInterface,
  entry: EntryInterface
) => {
  const { url } = siteQuery?.site?.siteMetadata;
  const {
    title,
    sectionHandle,
    featuredImage,
    postDate,
    dateUpdated,
    uri,
  } = entry;

  const siteUrl =
    sectionHandle === EntrySectionHandle.HOME ? url : `${url}/${uri}`;

  let schemaObjects = [];

  schemaObjects.push({
    ...webpageSchema,
    "@id": `${siteUrl}/#webpage`,
    url: siteUrl,
    name: `Tanveer Karim - Software Engineer | ${title}`,
    isPartOf: {
      "@id": `${url}/#website`,
    },
    datePublished: postDate,
    dateModified: dateUpdated,
  });

  if (featuredImage && featuredImage.length) {
    const { url: imageUrl, width, height } = featuredImage[0];
    schemaObjects.push({
      ...imageSchema,
      "@id": `${siteUrl}/#featuredImage`,
      url: imageUrl,
      width,
      height,
    });
  }

  switch (sectionHandle) {
    case EntrySectionHandle.ARTICLES:
      schemaObjects.push({
        ...articleSchema,
        "@id": `${siteUrl}/#article`,
        isPartOf: {
          "@id": `${siteUrl}/#webpage`,
        },
        headline: title,
        datePublished: postDate,
        dateModified: dateUpdated,
        mainEntityOfPage: {
          "@id": `${siteUrl}/#webpage`,
        },
        image:
          featuredImage && featuredImage.length
            ? {
                "@id": `${siteUrl}/#featuredImage`,
              }
            : null,
      });
      schemaObjects.push(authorSchema);
      break;
    case EntrySectionHandle.PROJECTS:
      schemaObjects.push({
        ...articleSchema,
        "@type": "CreativeWork",
        "@id": `${siteUrl}/#article`,
        isPartOf: {
          "@id": `${siteUrl}/#webpage`,
        },
        headline: title,
        datePublished: postDate,
        dateModified: dateUpdated,
        mainEntityOfPage: {
          "@id": `${siteUrl}/#webpage`,
        },
        image:
          featuredImage && featuredImage.length
            ? {
                "@id": `${siteUrl}/#featuredImage`,
              }
            : null,
      });
      schemaObjects.push(authorSchema);
      break;
    default:
      break;
  }

  const schemaObj = {
    "@context": "http://schema.org",
    "@graph": [
      { ...organizationSchema },
      { ...websiteSchema },
      ...schemaObjects,
    ],
  };

  return (
    <script type="application/ld+json">{JSON.stringify(schemaObj)}</script>
  );
};

export const Seo = (props: SeoInterface) => {
  const { description, lang, meta, title, entry } = props;

  const siteQuery: SiteMetadataInterface = useStaticQuery(
    graphql`
      query SiteMetadataQuery {
        ...siteMetadata
      }
    `
  );

  const { site } = siteQuery;

  const metaDescription: string = description || site.siteMetadata.description;
  const metaTags: MetaTagInterface[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    ...meta,
  ];

  if (entry) {
    const { featuredImage } = entry;
    if (featuredImage && featuredImage.length) {
      metaTags.push({
        property: "og:image",
        content: featuredImage[0].url,
      });
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={metaTags}
    >
      {entry && buildSchemaData(siteQuery, entry)}
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};
