import React, { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import { HeaderScroll, MenuState } from "./types";
import { Logo } from "../../components/logo";
import { ViewportSize } from "../../components/grid/content-blocks/container/types";

const StyledHeader = styled.header`
  margin: 0 0 3rem;
  transition: transform 0.3s ease;
  position: sticky;
  top: 0;
  background: white;
  z-index: 99;

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    &.scroll-down {
      transform: translate3d(0, -100%, 0);
    }
    &.scroll-top {
      transform: translate3d(0, 0, 0);
    }
  }
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid #eee;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  max-width: var(--content-width-max);

  @media (min-width: ${ViewportSize.TABLET}px) {
    width: var(--content-width-tablet);
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    padding: 2rem 1rem;
    width: var(--content-width-desktop);
  }
`;

const HeaderLogo = styled(Link)`
  margin-right: auto;
  display: flex;
  svg {
    width: 40px;
    height: 40px;
  }

  @media (min-width: ${ViewportSize.TABLET}px) {
    svg {
      width: 60px;
      height: 60px;
    }
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
  }
`;

const HeaderHamburger = styled.a`
  --menu-size: 30px;
  cursor: pointer;
  border-radius: 3px;
  background: #666;
  color: #666;
  width: var(--menu-size);
  height: var(--menu-size);
  display: flex;
  flex-flow: column nowrap;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    background: #fff;
    height: calc(var(--menu-size) / 5);
    width: 100%;
    margin-top: calc(var(--menu-size) / 5);
    transition: transform 0.3s ease;
    border-radius: ${(props) =>
      props.theme.menuState === MenuState.OPEN && "3px"};
  }

  &:before {
    transform: ${(props) =>
      props.theme.menuState === MenuState.OPEN
        ? "translate3d(0, 100%, 0) rotate(45deg)"
        : null};
  }

  &:after {
    transform: ${(props) =>
      props.theme.menuState === MenuState.OPEN
        ? "translate3d(0, -100%, 0) rotate(-45deg)"
        : null};
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    display: none;
  }
`;

const HeaderLink = styled(Link)`
  color: #333;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 1rem;
  margin-left: -1rem;
  width: 100%;
  display: block;
  line-height: 2;
  transition: color 0.3s ease;

  &.active {
    box-shadow: inset 3px 0 0 var(--brand);
  }

  &:hover,
  &:focus {
    color: var(--brand);
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    margin: 0 0 0 2rem;
    padding: 0;
    display: inline-block;
    line-height: inherit;
    display: inline-block;
    width: auto;

    &.active {
      box-shadow: inset 0 -3px 0 var(--brand);
    }
  }
`;

const HeaderLinkWrapper = styled.div`
  display: ${(props) =>
    props.theme.menuState === MenuState.OPEN ? "block" : "none"};
  flex-basis: 100%;
  padding: 1rem 0 0;
  margin: 1rem 0 0;
  border-top: 1px solid #eee;

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    display: block;
    flex-basis: auto;
    padding: 0;
    margin: 0;
    border: none;
  }
`;

enum ScrollClass {
  TOP = "scroll-top",
  UP = "scroll-up",
  DOWN = "scroll-down",
}

export const Header: React.FunctionComponent = ({ children }) => {
  let offset = 0;
  let direction = HeaderScroll.UP;

  const [scrollClass, setScrollClass] = useState(ScrollClass.TOP);
  const [menuState, setMenuState] = useState(MenuState.CLOSED);

  const onScrollHandler = () => {
    if (typeof window !== `undefined`) {
      const currentPageOffset = window.pageYOffset;

      if (currentPageOffset == 0) {
        setScrollClass(ScrollClass.TOP);
      }
      if (offset > currentPageOffset) {
        if (direction !== HeaderScroll.UP) {
          direction = HeaderScroll.UP;
          setScrollClass(ScrollClass.UP);
        }
      } else if (offset < currentPageOffset) {
        if (direction !== HeaderScroll.DOWN) {
          direction = HeaderScroll.DOWN;
          setScrollClass(ScrollClass.DOWN);
        }
      }

      offset = currentPageOffset <= 0 ? 0 : currentPageOffset;
    }
  };

  const toggleMenuState = () => {
    if (menuState === MenuState.CLOSED) {
      setMenuState(MenuState.OPEN);
      return;
    }

    setMenuState(MenuState.CLOSED);
  };

  useEffect(() => {
    document.addEventListener("scroll", onScrollHandler);
    return () => {
      document.removeEventListener("scroll", onScrollHandler);
    };
  }, []);

  return (
    <StyledHeader className={scrollClass}>
      <ThemeProvider theme={{ menuState }}>
        <HeaderWrapper>
          <HeaderLogo aria-label="Homepage link" to="/">
            <Logo />
          </HeaderLogo>
          <HeaderHamburger onClick={toggleMenuState} />
          <HeaderLinkWrapper>
            <HeaderLink to="/" activeClassName="active">
              Home
            </HeaderLink>
            <HeaderLink
              to="/articles"
              activeClassName="active"
              partiallyActive={true}
            >
              Articles
            </HeaderLink>
            <HeaderLink
              to="/work"
              activeClassName="active"
              partiallyActive={true}
            >
              Work
            </HeaderLink>
            <HeaderLink
              to="/contact"
              activeClassName="active"
              partiallyActive={true}
            >
              Contact
            </HeaderLink>
          </HeaderLinkWrapper>
        </HeaderWrapper>
      </ThemeProvider>
    </StyledHeader>
  );
};
