import { ReactNode } from "react";

export interface GridContainerInterface {
  children?: ReactNode;
}

export enum ViewportSize {
  MOBILE = 0,
  TABLET = 600,
  DESKTOP = 1024,
}

export enum GridArea {
  CONTENT = "content",
  CONTENT_LEFT = "content-left",
  CONTENT_RIGHT = "content-right",

  CONTENT_BLEED = "content-bleed",
  CONTENT_BLEED_LEFT = "content-bleed-left",
  CONTENT_BLEED_RIGHT = "content-bleed-right",

  FULL_SCREEN = "full-screen",

  PULLOUT_LEFT = "pullout-left",
  PULLOUT_RIGHT = "pullout-right",

  LEFT_ALIGNED = "left-aligned",
  RIGHT_ALIGNED = "right-aligned",
}
