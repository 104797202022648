export interface EntryAuthorInterface {
  firstName: string;
  lastName: string;
}

export interface EntryTagsInterface {
  uid: string;
  id: string;
  slug: string;
  title: string;
}

export interface EntryFeaturedImageInterface {
  [size: string]: string | number | undefined;
  uid: string;
  id: string;
  url: string;
  title: string;
  mimeType: string;
  kind: string;
  mediaTitle?: string;
  mediaAltText?: string;
  mediaCaption?: string;
  width: number;
  height: number;
}

export enum EntrySectionHandle {
  ARTICLES = "articles",
  PROJECTS = "projects",
  ABOUT = "aboutPage",
  HOME = "homePage",
}

export interface EntryInterface {
  id: string;
  uid: string;
  title: string;
  slug: string;
  dateUpdated: string;
  postDate: string;
  uri: string;
  sectionHandle: EntrySectionHandle;
  typeHandle: string;
  author: EntryAuthorInterface;
  featuredHeadingColor?: string;
  featuredTextColor?: string;
  featuredBackgroundColor?: string;
  featuredImage?: EntryFeaturedImageInterface[];
  featuredMarkup?: string;
  featuredStyles?: string;
  contentEditorFields: any[];
  entryTags: EntryTagsInterface[];
}

export interface PageContext {
  [key: string]: any;
}

export interface EntryQueryInterface {
  data: {
    craft: {
      entry: EntryInterface;
      draft?: EntryInterface;
    };
  };
  pageContext?: PageContext;
}

export interface EntriesQueryInterface {
  data: {
    craft: {
      entries: EntryInterface[];
    };
  };
  pageContext?: PageContext;
}
